<template>
  <div class="app-container" v-loading.fullscreen.lock="loading">
    <div class="tools_center">
      <!-- 页面顶部 -->
      <div class="tools_head">
        <div
          style="
            margin-left: 20px;
            height: 100%;
            display: flex;
            align-items: center;
          "
        >
          <div>
            <span
              v-if="!editOrderNameState"
              @click="editOrderNameState = true"
              >{{ editOrderData.name }}</span
            >
            <el-input
              v-if="editOrderNameState"
              size="small"
              style="width: 180px; margin-right: 10px"
              v-model="editOrderData.name"
              @blur="editOrderNameState = false"
            ></el-input>
          </div>
          <div style="display:flex;align-items:center;justify-content: space-around">
            <img
              title="无法撤销"
              v-if="!canUndo"
              @click="onUndo"
              src="@/assets/SlnDesign/return0.png"
              style="width: 22px; height: 18px; margin: 5px 10px"
            />
            <img
              title="撤销"
              v-if="canUndo"
              @click="onUndo"
              src="@/assets/SlnDesign/return.png"
              style="width: 22px; height: 18px; margin: 5px 10px"
            />
            <img
              title="无法前进"
              v-if="!canRedo"
              @click="onRedo"
              src="@/assets/SlnDesign/next0.png"
              style="width: 22px; height: 18px; margin: 5px 10px"
            />
            <img
              title="前进"
              v-if="canRedo"
              @click="onRedo"
              src="@/assets/SlnDesign/next.png"
              style="width: 22px; height: 18px; margin: 5px 10px"
            />
            <img
              title="矩形工具"
              @click="addShape"
              :disabled="!addShapeState"
              src="@/assets/SlnDesign/shape.png"
              style="width: 18px; height: 18px; margin: 5px 10px"
            />
            <img
              title="文字工具"
              @click="openAddTextDialog"
              :disabled="!addTextState"
              src="@/assets/SlnDesign/text.png"
              style="width: 24px; height: 18px; margin: 5px 10px"
            />
            <!-- <el-button @click="exportImage">导出图片</el-button> -->
          </div>
        </div>
        <div style="margin-right: 10px">
          <img
            title="退出"
            @click="exitDesign"
            src="@/assets/SlnDesign/back.png"
            style="width: 22px; height: 20px; margin: 5px 15px"
          />
          <img
              title="导出图片"
              @click="exportImage"
              src="@/assets/SlnDesign/checkOrder.png"
              style="width: 22px; height: 22px; margin: 5px 0"
            />
          <img
            title="生成方案"
            @click="openSaveDesignDialog"
            src="@/assets/SlnDesign/save.png"
            style="width: 20px; height: 20px; margin: 5px 15px"
          />
        </div>
      </div>
      <SketchRule
        :lang="lang"
        :thick="thick"
        :scale="scale"
        :width="rulerWidth"
        :height="rulerHeight"
        :startX="startX"
        :startY="startY"
        :horLineArr="lines.h"
        :verLineArr="lines.v"
        :cornerActive="true"
        v-show="isShowRuler"
      />
      <div
        id="container"
        style="position: absolute; left: 275px; top: 10vh"
      ></div>
      <div
        v-if="beginState == false"
        style="
          width: 100%;
          position: absolute;
          left: 0px;
          top: 10vh;
          padding-top: 8vh;
        "
      >
        <img
          src="@/assets/SlnDesign/empty.png"
          style="width: 270px; height: 240px; margin: 30px auto; display: block"
        />
        <el-button
          type="text"
          style="
            margin: 0 auto;
            margin-top: 15px;
            display: block;
            border-radius: 8px;
          "
          >暂无数据</el-button
        >
      </div>

      <!-- <div v-else id="container"></div> -->
    </div>

    <!-- 页面左侧边栏 -->
    <div class="tools_box">
      <!-- 楼层图纸 -->
      <div class="floor_item">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 8px;
            padding-top: 6px;
          "
        >
          <span>楼层图纸</span>
          <el-button type="text" @click="floorDialogVisiable = true"
            >+新建楼层</el-button
          >
        </div>
        <el-divider></el-divider>
        <div style="margin-left: 6px; height: 42vh">
          <el-scrollbar style="height: 20vh">
            <div class="floor_table">
              <el-table
                :data="filteredFloorList"
                style="width: 100%"
                :show-header="false"
                ref="singleTable"
                highlight-current-row
                @current-change="handleCurrentChange"
                :key="isToggle"
              >
                <el-table-column prop="fileUrl" label="图片" show-header>
                  <template slot-scope="{ row }">
                    <div style="display: flex; align-items: center">
                      <img
                        :src="row.fileUrl"
                        style="width: 30px; margin-right: 6px"
                        alt=""
                      />
                      <span style="font-size: 12px">{{ row.name }}</span>
                      <el-button
                        type="text"
                        icon="el-icon-delete"
                        @click="delFloor()"
                      ></el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-scrollbar>
        </div>
      </div>
      <!-- 图纸清单 -->
      <div class="item">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 6px;
            padding-bottom: 0;
          "
        >
          <span>图纸清单</span>
          <el-button type="text" @click="roomDialogVisiable = true"
            >+创建房间</el-button
          >
        </div>
        <el-divider></el-divider>
        <div style="height: 86%; overflow: hidden">
          <el-scrollbar style="height: 86%">
            <el-tree
              :data="roomListData"
              style="min-height: 86%; width: 220px; font-size: 13px"
              class="custom-tree"
              node-key="index"
              default-expand-all
              :props="defaultProps"
              highlight-current-row
              @node-click="handleNodeClick"
              :expand-on-click-node="false"
              :key="updateKey"
            >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <!-- <span>{{ node }}</span> -->
                <template v-if="!data.isRoot">
                  <!-- ({{ data }}) -->
                  <img :src="data.thumbImage" style="width: 15px" />
                </template>
                <span style="width: 160px; white-space: normal">
                  {{ node.label }}
                  <template v-if="data.isRoot && data.children.length >= 0">
                    ({{ data.children.length }})
                  </template>
                </span>
                <span class="action-treebutton">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0"
                    @click="() => delRoom(node, data)"
                  >
                    <i class="el-icon-delete"></i>
                  </el-button>
                </span>
              </span>
            </el-tree>
          </el-scrollbar>
        </div>
      </div>
    </div>
    <!-- 页面右侧边栏 -->
    <div class="tools_box2">
      <div class="item">
        <!-- 商品条件查询 -->
        <el-form size="mini" :inline="true" :model="form">
          <el-row style="margin-left: 10px">
            <el-col :span="12">
              <el-form-item>
                <el-select
                  clearable
                  v-model="form.ProductTypeId"
                  placeholder="商品类型"
                  @change="typeSelectChange"
                >
                  <el-option
                    v-for="item in proTypeOption"
                    :label="item.label"
                    :value="item.value"
                    :key="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <el-select
                  clearable
                  v-model="form.ProductBrandId"
                  placeholder="商品品牌"
                  @change="brandSelectChange"
                >
                  <el-option
                    v-for="item in proBrandOption"
                    :label="item.label"
                    :value="item.value"
                    :key="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <el-input
              clearable
              v-model="form.keyWorlds"
              placeholder="产品名称/型号"
              style="margin: 0 10px; width: 210px"
              prefix-icon="el-icon-search"
              @keydown.enter.native="searchProduct"
            />
          </el-form-item>
        </el-form>
        <!-- 商品列表 -->
        <el-scrollbar style="height: 90%">
          <el-table
            :data="productList"
            style="width: 100%"
            :show-header="false"
            ref="singleTable"
            v-loading="loading"
            highlight-current-row
          >
            <el-table-column prop="thumbImage" label="图片" show-header>
              <template slot-scope="{ row }">
                <!-- <div
                  style="display: flex; align-items: center"
                  @dragstart="startDrag(row, $event)"
                  draggable
                > -->

                <div style="display: flex; align-items: center">
                  <div>
                    <img
                      draggable
                      @mousedown="startDrag(row, $event)"
                      :src="row.thumbImage"
                      style="width: 50px; margin-right: 12px"
                      alt=""
                    />
                    <div style="padding: 5px 0; text-align: center">
                      ¥{{ row.productPrice }}
                    </div>
                  </div>

                  <div style="line-height: 20px">
                    <div style="font-size: 12px">{{ row.productName }}</div>

                    <el-select
                      v-model="row.modelName"
                      placeholder="商品型号"
                      size="mini"
                      style="margin-bottom: 5px"
                    >
                      <el-option
                        v-for="item in row.modelOptions"
                        :label="item.label"
                        :value="item.value"
                        :key="item.value"
                      ></el-option>
                    </el-select>

                    <el-select
                      v-model="row.productSpec"
                      placeholder="商品规格"
                      @change="attributeChange(row, $event)"
                      size="mini"
                      style="margin: 5px 0"
                    >
                      <el-option
                        v-for="item in row.attribute"
                        :label="item.label"
                        :value="item"
                        :key="item.value"
                      ></el-option>
                    </el-select>

                    <el-input-number
                      :precision="0"
                      style="width: 100%"
                      size="mini"
                      :min="1"
                      v-model="row.number"
                    />
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-button
            v-if="productList.length != form.total"
            style="margin-left: 90px"
            type="text"
            @click="moreListData"
            >加载更多</el-button
          >
          <p style="margin-left: 84px; font-size: 13px; color:#666666;" v-else>已经到底了</p>
        </el-scrollbar>
      </div>
    </div>
    <!--标尺和缩放比例-->
    <div class="tools_floatfoot" v-if="beginState != false">
      <div class="tools_floatfoot1" @click="changeRulerState">
        <img src="@/assets/SlnDesign/rulers.png" alt="" />
      </div>
      <div
        class="tools_floatfoot2"
        @mouseover="scaleBtnState = true"
        @mouseleave="showScaleBtn"
      >
        <div v-if="scaleBtnState">
          <i class="el-icon-minus" @click="scaleReduceChange"></i>
          <i
            class="el-icon-plus"
            style="margin: 0 10px"
            @click="scaleAddChange"
          ></i>
          <!-- <el-button icon="el-icon-minus" circle></el-button>
          <el-button icon="el-icon-plus" circle></el-button> -->
        </div>
        <span v-if="!selectScaleState" @mouseover="selectScaleState = true"
          >{{ canvasScale }}%</span
        >
        <!-- <span v-if="selectScaleState">{{canvasScale}}%</span> -->
        <el-select
          :value="canvasScale + '%'"
          placeholder="请选择"
          v-if="selectScaleState"
          @change="selectScaleChange"
        >
          <el-option
            v-for="item in scaleOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <!--打开添加文字弹框-->
    <el-dialog
      title="添加文字"
      :visible.sync="addTextDialogVisible"
      width="36%"
    >
      <el-form>
        <el-form-item label="添加文字" style="width: 540px">
          <!-- {{ createFloorData.name }} -->
          <el-input
            placeholder="请输入想要添加的文字"
            v-model="inputAddTexts"
            style="width: 420px"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="addTextDialogVisible = false"
          >取消</el-button
        >
        <el-button type="primary" @click="addText">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 创建楼层 floor -->
    <el-dialog title="创建楼层" :visible.sync="floorDialogVisiable" width="36%">
      <el-form :model="createFloorData">
        <el-form-item label="楼层名称" style="width: 310px">
          <!-- {{ createFloorData.name }} -->
          <el-input
            placeholder="请输入楼层名称"
            v-model="createFloorData.name"
            style="width: 240px"
          ></el-input>
        </el-form-item>
        <el-form-item label="楼层户型图" style="height: 148px">
          <el-upload
            ref="upLoad"
            :action="uploadImgUrl"
            list-type="picture-card"
            :limit="1"
            :on-exceed="handleExceed"
            :on-success="handleUploadSuccess"
            :on-error="handleUploadError"
            :before-upload="handleBeforeUpload"
            :on-progress="uploadProcess"
            :file-list="fileList"
            :class="{ hide: this.fileList.length >= 1 }"
          >
            <img slot="default" src="@/assets/SlnDesign/upload.png" alt="" />
            <div slot="file" slot-scope="{ file }">
              <img
                class="el-upload-list__item-thumbnail"
                :src="file.data"
                alt=""
              />
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                  v-if="!imageDisabled"
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
          <el-dialog :append-to-body="true" :visible.sync="uploadDialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="floorDialogVisiable = false"
          >取消</el-button
        >
        <el-button type="primary" @click="addNewFloor">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 创建房间 room -->
    <el-dialog title="创建房间" :visible.sync="roomDialogVisiable" width="36%">
      <el-form :model="createFloorData">
        <el-form-item label="房间名称" style="width: 540px">
          <!-- {{ createFloorData.name }} -->
          <el-input
            placeholder="请输入房间名称"
            v-model="createRoomName"
            style="width: 420px"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="roomDialogVisiable = false"
          >取消</el-button
        >
        <el-button type="primary" @click="addNewRoom">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      v-if="saveDesignDialogVisible"
      :visible.sync="saveDesignDialogVisible"
      width="72%"
    >
      <div style="display: flex">
        <div class="save_box">
          <div style="text-align: center; margin-bottom: 20px">
            <p
              style="
                border: 2px solid #8c50ff;
                width: 180px;
                margin: 0 auto;
                line-height: 36px;
              "
            >
              {{ editOrderData.name }}
            </p>
          </div>
          <el-tabs v-model="designActiveName">
            <el-tab-pane
              label="房间"
              style="height: 100%; width: 99%"
              name="first"
            >
              <el-scrollbar style="height: 59vh">
                <template v-if="roomProductList.length > 0">
                  <div :key="index" v-for="(item, index) in roomProductList">
                    <div :key="index" v-for="(element, index) in item.rooms">
                      <p style="font-weight: bold">
                        {{ item.name }} {{ element.label }}
                      </p>
                      <el-table
                        :data="element.children"
                        border
                        style="width: 100%"
                        max-height="500"
                      >
                        <el-table-column label="商品名称" show-header>
                          <template slot-scope="{ row }">
                            <div style="display: flex; align-items: center">
                              <img
                                :src="row.thumbImage"
                                style="width: 42px; margin-right: 20px"
                                alt=""
                              />
                              <span style="font-size: 12px">{{
                                row.productName
                              }}</span>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column prop="productBrandName" label="品牌">
                        </el-table-column>

                        <el-table-column prop="modelName" label="型号">
                        </el-table-column>
                        <el-table-column prop="productSpec" label="规格">
                        </el-table-column>
                        <el-table-column prop="productPrice" label="价格">
                        </el-table-column>
                        <el-table-column prop="number" label="数量" width="160">
                          <template slot-scope="{ row }">
                            <el-input-number
                              v-model="row.number"
                              size="mini"
                              @change="
                                (val) =>
                                  changeProductNumber(
                                    val,
                                    row,
                                    item.name,
                                    element.label
                                  )
                              "
                              :precision="0"
                              :min="1"
                            />
                          </template>
                        </el-table-column>
                        <el-table-column prop="allPrice" label="小计">
                        </el-table-column>
                        <el-table-column label="操作">
                          <template slot-scope="scope">
                            <!--修改型号和规格-->
                            <!-- <el-button
                              size="mini"
                              type="primary"
                              icon="el-icon-edit"
                              @click="
                                openSpecDialog(
                                  scope.row,
                                  item.name,
                                  element.label
                                )
                              "
                              >编辑</el-button
                            > -->
                            <!--删除商品时floor的jsonData中也删除-->
                            <el-button
                              size="mini"
                              type="danger"
                              icon="el-icon-delete"
                              @click="
                                DeleteNowProduct(
                                  scope.row,
                                  item.name,
                                  element.label
                                )
                              "
                              >删除</el-button
                            >
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>
                </template>
              </el-scrollbar>
            </el-tab-pane>
            <el-tab-pane label="种类" name="second">
              <el-scrollbar style="height: 59vh">
                <template v-if="AllProductList.length > 0">
                  <div :key="index" v-for="(item, index) in AllProductList">
                    <p style="font-weight: bold">{{ item.type }}</p>
                    <el-table :data="item.value" border style="width: 100%" max-height="500">
                      <el-table-column label="商品名称" show-header>
                        <template slot-scope="{ row }">
                          <div style="display: flex; align-items: center">
                            <img
                              :src="row.thumbImage"
                              style="width: 42px; margin-right: 6px"
                              alt=""
                            />
                            <span style="font-size: 12px">{{
                              row.productName
                            }}</span>
                            <!-- <span style="font-size: 12px">{{ row }}</span> -->
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="productBrandName"
                        label="品牌"
                        width="150"
                      >
                      </el-table-column>

                      <el-table-column
                        prop="modelName"
                        label="型号"
                        width="210"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="productSpec"
                        label="规格"
                        width="120"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="productPrice"
                        label="价格"
                        width="100"
                      >
                      </el-table-column>
                      <el-table-column prop="number" label="数量" width="100">
                      </el-table-column>
                      <el-table-column prop="allPrice" label="小计" width="100">
                      </el-table-column>
                    </el-table>
                  </div>
                </template>
              </el-scrollbar>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="save_footer">
          <span>产品总额：</span>
          <span style="color: red; margin-right: 20px"
            >￥{{ allproductPrice }}.00</span
          >
          <!-- <el-button type="info" @click="saveDesignDialogVisible=false"
            >取消</el-button
          > -->
          <el-button type="info" @click="closeSaveDialog">取消</el-button>
          <el-button type="primary" @click="editNewdragOrder">确 定</el-button>
        </div>
      </div>
      <!--修改商品信息-->
      <el-dialog
        title="修改商品信息"
        :visible.sync="SpecDialogVisible"
        :append-to-body="true"
        width="36%"
      >
        <el-form :model="nowSelectProduct">
          <!-- <el-form-item label="房间名称" style="width:540px;">
            {{ nowSelectProduct }}
          </el-form-item> -->
          <el-select
            clearable
            v-model="nowSelectProduct.productModel"
            placeholder="商品型号"
          >
            <el-option
              v-for="item in nowSelectProduct.modelOptions"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            ></el-option>
          </el-select>
          <el-select
            v-model="nowSelectProduct.productSpec"
            placeholder="商品规格"
            @change="attributeChange(nowSelectProduct, $event)"
          >
            <el-option
              v-for="item in nowSelectProduct.attribute"
              :label="item.label"
              :value="item"
              :key="item.value"
            ></el-option>
          </el-select>
          <el-input
            v-model="nowSelectProduct.productPrice"
            placeholder="商品价格"
            disabled
          ></el-input>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="info" @click="SpecDialogVisible = false"
            >取消</el-button
          >
          <el-button type="primary" @click="editNowProduct">确 定</el-button>
        </div>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import { Graph } from '@antv/x6'
import { Dnd } from '@antv/x6-plugin-dnd'
import { Transform } from '@antv/x6-plugin-transform'
import { Clipboard } from '@antv/x6-plugin-clipboard'
import { Selection } from '@antv/x6-plugin-selection'
import { Keyboard } from '@antv/x6-plugin-keyboard'
import { Snapline } from '@antv/x6-plugin-snapline'
import { History } from '@antv/x6-plugin-history'
import SketchRule from 'vue-sketch-ruler'

import { Export } from '@antv/x6-plugin-export'
import {
  getFilterProduct,
  getProductById,
  getProducts,
  getAllBrand,
  getAllType,
} from '@/api/product.js'
import {
  dragOrderAdd,
  dragOrderEdit,
  ImgUploadOSS,
  getDragOrderById,
} from '@/api/DragOrder/index'

import { Canvg, presets } from 'canvg'

export default {
  name: '',
  props: [''],
  data() {
    return {
      loading: true,
      OrderDialogVisiable: true,
      editOrderData: {
        name: '',
        remark: '',
        floors: [],
      },
      OrderFormRules: {
        name: [{ required: true, message: '请输入方案名称', trigger: 'blur' }],
      },
      // editOrderData: {},
      //文件上传部分
      uploadImgUrl: process.env.VUE_APP_API_URL + '/api/DragOrder/Upload',
      fileType: ['png', 'jpg', 'jpeg'],
      //楼层图片地址
      floorImageUrl: '',
      // upLoadFileData: {text: 111},

      graph: null,
      dnd: null,
      //撤销重做
      canUndo: false,
      canRedo: false,
      parentNode: null,
      childNode: [],
      visable: true,
      //楼层列表
      beginState: false,
      editOrderNameState: false,
      floorList: [],
      //当前选择的楼层
      nowSelectFloor: {
        fileUrl: '',
        name: '',
        rooms: [],
      },
      //当前选择的房间
      nowSelectRoom: {
        label: '',
        isRoot: false,
        products: [],
      },
      //当前按种类分类的商品列表
      AllProductList: [],
      //当前按房间分类的商品列表
      roomProductList: [],
      // 编辑选中的商品
      nowSelectProduct: {},
      //房间列表
      roomListData: [],
      //树组件配置项
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      //新建楼层弹出框是否可见
      floorDialogVisiable: false,
      //文件上传部分
      dialogImageUrl: '',
      uploadDialogVisible: false,
      imageDisabled: false,
      fileList: [],
      //新创建的楼层信息
      createFloorData: {
        // dragOrderInfoId: '',
        name: '',
        fileUrl: '',
        jsonData: '',
        rooms: [],
      },
      //新建房间弹出框是否可见
      roomDialogVisiable: false,
      //新创建的房间名称
      createRoomName: '默认房间',
      //商品类型选项
      proTypeOption: [],
      //商品品牌选项
      proBrandOption: [],
      //商品列表
      productList: [],
      //商品查询
      form: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        keyWorlds: '',
        ProductBrandId: null,
        ProductTypeId: null,
        SupplierId: null,
      },
      //保存点位图设计方案弹出框是否可见
      saveDesignDialogVisible: false,
      //打印点位图设计方案弹出框是否可见
      printDesignDialogVisible: false,
      //设计点位图方案的房子信息
      designActiveName: 'first',

      // pNodeDefaultPosition: {x:'', y:''},
      jsonData: {},
      oldJsonData: {},
      allproductPrice: 0,
      oldPosition: {},
      oldPositionList: [],

      //修改商品规格和型号弹出框是否可见
      SpecDialogVisible: false,

      //定时器，每十秒自动保存一次
      interval: null,
      timerjsonData: '',
      //是否重做
      redoState: false,
      //是否撤销
      undoState: false,
      // istoggle: true,

      // 是否可以添加形状
      addShapeState: false,
      // 是否可以添加文字
      addTextState: false,
      //添加文字弹出框是否可见
      addTextDialogVisible: false,
      //添加的文字信息
      inputAddTexts: '',

      //删除的节点信息
      deleteCellList: [],

      //删除的商品信息
      deleteProList: [],
      updateKey: true,
      isToggle: false,

      //画布缩放比例
      canvasScale: 0,

      //标尺
      scale: 1, //658813476562495, //1,
      startX: 0,
      startY: 0,
      lines: {
        h: [],
        v: [],
      },
      rulerWidth: 0,
      rulerHeight: 0,
      thick: 20,
      lang: 'zh-CN',
      isShowRuler: false,
      // isShowReferLine: false
      scaleBtnState: false,
      selectScaleState: false,
      scaleOptions: [
        {
          label: '300%',
          value: 3,
        },
        {
          label: '200%',
          value: 2,
        },
        {
          label: '100%',
          value: 1,
        },
        {
          label: '50%',
          value: 0.5,
        },
        {
          label: '25%',
          value: 0.25,
        },
      ],
      copyNodeId: "",
      // isAltPressed: false,
    }
  },
  created() {
    this.getInfoById()
    this.getProductsData()
    this.getProductTypeList()
    this.getProductBrandList()
  },

  components: {
    SketchRule,
  },

  computed: {
    filteredFloorList() {
      return this.floorList.filter((floor) => !floor.isDelete)
    },
  },

  beforeMount() {},

  mounted() {
    this.init()
    // window.addEventListener('keydown', this.handleKeyDown)
    // window.addEventListener('keyup', this.handleKeyUp)
  },
  beforeDestroy() {
    clearInterval(this.interval)
    // window.removeEventListener('keydown', this.handleKeyDown)
    // window.removeEventListener('keyup', this.handleKeyUp)
  },

  methods: {
    async exportImage() {
      const preset = presets.offscreen()

      let svgData = await new Promise((resolve, reject) => {
        this.graph.toSVG((dataUri) => {
          resolve(dataUri)
        })
      })

      const canvas = new OffscreenCanvas(1200, 800)
      const ctx = canvas.getContext('2d')

      console.log('svgData', svgData)

      let v = await Canvg.fromString(ctx, svgData, preset)

      await v.render()

      const blob = await canvas.convertToBlob()
      const pngUrl = URL.createObjectURL(blob)
      console.log('pngUrl', pngUrl)
    },
    // handleKeyDown(event) {
    //   console.log(event)
    //   // if (event.key === 'Alt' && event.altKey) {
    //   //   // console.log("键盘alt键被按下", event)
    //   //   this.isAltPressed = true
    //   // }
    //   if((event.key === 'Control' || event.key === 'Alt') && event.altKey && event.ctrlKey){
    //     console.log("键盘ctrl和alt键被按下", event)
    //   }
    //   // if(event.key === 'ArrowUp'||event.key === 'ArrowDown' || event.key === 'ArrowLeft' || event.key === 'ArrowRight'){
    //   // }

    //   // if(event.key === 'ArrowUp' && event.altKey){
    //   //   this.isUpPressed = true;
    //   // }
    //   // if(event.key === 'ArrowDown' && event.altKey){
    //   //   this.isDownPressed = true;
    //   // }
    //   // if(event.key === 'ArrowLeft' && event.altKey){
    //   //   this.isLeftPressed = true;
    //   // }
    //   // if(event.key === 'ArrowRight' && event.altKey){
    //   //   this.isRightPressed = true;
    //   // }

    // },
    // handleKeyUp(event) {
    //   if((event.key === 'Control' || event.key === 'Alt') && event.altKey && event.ctrlKey){
    //     console.log("键盘ctrl和alt键被按下", event)
    //     // this.isAltPressed = false
    //   }
    //   // if (event.key === 'ArrowUp' && !event.altKey) {
    //   //   this.isUpPressed = false
    //   // }
    //   // if (event.key === 'ArrowDown' && !event.altKey) {
    //   //   this.isAltPressed = false
    //   // }
    //   // if (event.key === 'ArrowLeft' && !event.altKey) {
    //   //   this.isAltPressed = false
    //   // }
    //   // if (event.key === 'ArrowRight' && !event.altKey) {
    //   //   this.isAltPressed = false
    //   // }
    // },
    async getInfoById() {
      let id = this.$route.params.id
      let { data } = await getDragOrderById(id)
      this.editOrderData = data
      if (this.editOrderData.name != '') {
        this.floorList = [...this.editOrderData.floors]
        const promises = this.floorList.map(async (floor) => {
          const promises = floor.rooms.map(async (room) => {
            let prolist = []
            const promises = room.products.map(async (product) => {
              try {
                // console.log('product', product)
                let id = product.productId
                let proData = {}
                let { data } = await getProductById(id)
                proData = { ...product }
                proData.productName = data.productName
                proData.modelName = proData.productModel
                proData.productBrandId = data.productBrandId
                proData.productTypeId = data.productTypeId
                proData.productBrandName = data.productBrandName
                proData.productTypeName = data.productTypeName
                proData.thumbImage = data.thumbImage
                proData.number = proData.productNumber
                prolist.push(proData)
              } catch (error) {
                this.$message.error(error)
              }
            })
            await Promise.all(promises)
            room.children = prolist
            room.label = room.name
            room.isRoot = true
          })
          await Promise.all(promises)
        })
        await Promise.all(promises)
        this.OrderDialogVisiable = false
        this.loading = false
      }
    },
    changeRulerState() {
      this.isShowRuler = !this.isShowRuler
      this.updateDimensions()
    },
    updateDimensions() {
      this.rulerWidth = this.$el.querySelector('.tools_center').clientWidth
      this.rulerHeight = this.$el.querySelector('.tools_center').clientHeight
    },
    showScaleBtn() {
      if (!this.selectScaleState) {
        this.scaleBtnState = false
      }
    },
    //选择缩放比例
    selectScaleChange(value) {
      this.scale = parseFloat(value.toFixed(2))
      this.rulerWidth =
        this.$el.querySelector('.tools_center').clientWidth / this.scale
      this.rulerHeight =
        this.$el.querySelector('.tools_center').clientHeight / this.scale
      if (value == 1) {
        this.canvasScale = parseFloat(value) * 100
      } else {
        this.canvasScale = parseFloat(value.toFixed(2)) * 100
      }
      // // 设置新的缩放比例
      this.graph.zoomTo(value)
      this.selectScaleState = false
      this.scaleBtnState = false
    },
    //加减缩放比例
    scaleReduceChange() {
      // 获取当前缩放比例
      let oldScale = this.graph.zoom()
      let newScale = oldScale - 0.1
      // console.log(oldScale)
      this.scale = parseFloat(newScale.toFixed(2))
      this.rulerWidth =
        this.$el.querySelector('.tools_center').clientWidth / this.scale
      this.rulerHeight =
        this.$el.querySelector('.tools_center').clientHeight / this.scale
      if (newScale == 1) {
        this.canvasScale = parseFloat(newScale) * 100
      } else {
        this.canvasScale = parseFloat(newScale.toFixed(2)) * 100
      }
      // // 设置新的缩放比例
      this.graph.zoomTo(newScale)
    },
    scaleAddChange() {
      // 获取当前缩放比例
      let oldScale = this.graph.zoom()
      let newScale = oldScale + 0.1
      console.log(oldScale)
      this.scale = parseFloat(newScale.toFixed(2))
      this.rulerWidth =
        this.$el.querySelector('.tools_center').clientWidth / this.scale
      this.rulerHeight =
        this.$el.querySelector('.tools_center').clientHeight / this.scale
      if (newScale == 1) {
        this.canvasScale = parseFloat(newScale) * 100
      } else {
        this.canvasScale = parseFloat(newScale.toFixed(2)) * 100
      }
      // // 设置新的缩放比例
      this.graph.zoomTo(newScale)
    },
    visableHandel() {
      this.visable = !this.visable

      this.childNode.forEach((node) => {
        node.prop('visible', this.visable)
      })
    },
    getJsonData() {
      this.jsonData = {}
      var json = this.graph.toJSON()
      this.jsonData = json
    },
    async init() {
      if (this.graph != null) {
        this.graph.dispose()
        this.graph = null
      }
      this.graph = new Graph({
        container: document.getElementById('container'),
        autoResize: true,
        panning: true,
        mousewheel: true,
        background: {
          color: '#FFFFFF',
          //color: '#eff3fb',
        },
        grid: {
          visible: false,
          type: 'doubleMesh',
          args: [
            {
              color: '#eee', // 主网格线颜色
              thickness: 1, // 主网格线宽度
            },
            {
              color: '#ddd', // 次网格线颜色
              thickness: 1, // 次网格线宽度
              factor: 4, // 主次网格线间隔
            },
          ],
        },
        translating: {
          restrict(view) {
            if (view) {
              const cell = view.cell
              if (cell.isNode()) {
                const parent = cell.getParent()
                if (parent) {
                  return parent.getBBox()
                }
              }
            }
          },
        },
      })

      const resizingOptions = {
        enabled: true,
        orthogonal: false,
        restrict: false,
        preserveAspectRatio: false,
      }

      const rotatingOptions = {
        enabled: false,
        orthogonal: false,
        restrict: false,
        preserveAspectRatio: false,
      }

      this.dnd = new Dnd({
        target: this.graph,
        validateNode: (node) => {
          if (!this.parentNode) {
            return false
          }
          let { x: px, y: py } = this.parentNode.store.data.position
          let { width: pw, height: ph } = this.parentNode.store.data.size

          let { x: nx, y: ny } = node.store.data.position
          let { width: nw, height: nh } = node.store.data.size
          let isOk = !(
            nx < px ||
            nx + nw > px + pw ||
            ny < py ||
            ny + nh > py + ph
          )

          if (isOk) {
            let ProductData = node.data
            ProductData.label =
              node.data.productName +
              `_${node.data.modelName}_${node.data.productSpec}(x${node.data.number})`
            ProductData.position = node.store.data.position
            ProductData.cellId = node.id
            ProductData.size = node.store.data.size
            this.floorList.forEach((item) => {
              if (item.name == this.nowSelectFloor.name) {
                item.jsonData = this.nowSelectFloor.jsonData
                item.rooms.forEach((element) => {
                  if (element.label == this.nowSelectRoom.label) {
                    element.children.push(ProductData)
                    // console.log('productData', data)
                    let newProduct = {
                      cellId: ProductData.cellId,
                      productId: ProductData.id,
                      productImage: ProductData.thumbImage,
                      productModel: ProductData.modelName,
                      productSpec: ProductData.productSpec,
                      productPrice: ProductData.productPrice,
                      productNumber: ProductData.number,
                    }
                    element.products.push(newProduct)
                  }
                })
                this.roomListData = JSON.parse(
                  JSON.stringify(this.nowSelectFloor.rooms)
                )
                this.roomListData = this.roomListData.filter(
                  (room) =>
                    room.isDelete == false || !room.hasOwnProperty('isDelete')
                )
              }
            })

            this.childNode.push(node)
            this.parentNode.addChild(node)
            this.getJsonData()
            this.nowSelectFloor.jsonData = JSON.stringify(this.jsonData)
          }
          return isOk
        },
        getDragNode: (node) => node.clone({ keepId: true }),
        getDropNode: (node) => node.clone({ keepId: true }),
      })

      this.graph
        .use(
          new Transform({
            resizing: resizingOptions,
            rotating: rotatingOptions,
          })
        )
        .use(
          new History({
            enabled: true,
          })
        )
        .use(
          new Export(),
        )
        .use(
          new Clipboard({
            enabled: true,
          }),
        )
        .use(
          new Snapline({
            enabled: true,
          }),
        )
        .use(
          new Selection({
            enabled: true,
            // showNodeSelectionBox: true,
          }),
        )
        .use(
          new Keyboard({
            enabled: true,
            global: true,
          }),
        )

      var img = new Image()
      img.src = this.nowSelectFloor.fileUrl

      await new Promise((resovle, reject) => {
        img.onload = () => {
          resovle()
        }
      })
      if (this.oldJsonData != {} && this.oldJsonData.cells != undefined) {
        this.graph.fromJSON(this.oldJsonData)
        this.parentNode = this.graph.getNodes()[0]
        let childCells = []
        let cellList = this.graph.toJSON()
        cellList.cells.forEach((cell) => {
          if (cell.zIndex != 1) {
            childCells.push(cell)
          }
        })
        this.childNode = childCells
      } else {
        if (this.nowSelectFloor.name != '') {
          var rect = document
            .getElementById('container')
            .getBoundingClientRect()

          let imgurl = this.nowSelectFloor.fileUrl
          this.parentNode = this.graph.addNode({
            shape: 'image',
            x: (rect.width - img.width) / 2,
            y: (rect.height - img.height) / 2,
            width: img.width,
            height: img.height,
            imageUrl: imgurl,
          })

          this.childNode = []
          this.getJsonData()
          this.nowSelectFloor.jsonData = JSON.stringify(this.jsonData)
        }
      }

      this.getJsonData()
      this.nowSelectFloor.jsonData = JSON.stringify(this.jsonData)
      const currentZoom = this.graph.zoom()
      console.log('默认缩放比例', currentZoom)
      if (currentZoom == 1) {
        this.canvasScale = currentZoom * 100
      } else {
        this.canvasScale = currentZoom.toFixed(2) * 100
      }
      this.graph.on('scale', ({ sx, sy, ox, oy }) => {
        const currentZoom = this.graph.zoom()
        this.scale = parseFloat(currentZoom.toFixed(2))
        this.rulerWidth =
          this.$el.querySelector('.tools_center').clientWidth / this.scale
        this.rulerHeight =
          this.$el.querySelector('.tools_center').clientHeight / this.scale
        if (currentZoom == 1) {
          this.canvasScale = currentZoom * 100
        } else {
          this.canvasScale = currentZoom.toFixed(2) * 100
        }
      })
      this.graph.on('cell:mouseenter', ({ cell }) => {
        if (cell.isNode()) {
          if (cell.id == this.parentNode.id) {
            return
          }
          if (cell.zIndex != 1) {
            cell.addTools([
              {
                name: 'boundary',
                args: {
                  attrs: {
                    fill: '#7c68fc',
                    stroke: '#333',
                    'stroke-width': 1,
                    'fill-opacity': 0.2,
                  },
                },
              },
              {
                name: 'button-remove',
                args: {
                  x: 0,
                  y: 0,
                },
              },
            ])
          }
        }
      })

      this.graph.on('cell:mouseleave', ({ cell }) => {
        if (cell.zIndex != 1) {
          cell.removeTools()
        }
      })

      this.graph.bindKey('ctrl+c', () => {
        const cells = this.graph.getSelectedCells()
        // console.log('ctrl+c', cells)
        if (cells.length) {
          this.graph.copy(cells)
          let oldNodeData = cells[0].store.data;
          this.copyNodeId = oldNodeData.id;
        }
        return false
      })

      this.graph.bindKey('ctrl+v', () => {
        if (!this.graph.isClipboardEmpty()) {
          const cells = this.graph.paste({ offset: 32 })

          let newNodeData = cells[0].store.data;
          // console.log('ctrl+v', newNodeData)
          this.getJsonData()
          this.nowSelectFloor.jsonData = JSON.stringify(this.jsonData)
          this.floorList = this.floorList.map(floor=>{
            floor.rooms = floor.rooms.map(room=>{
              let newChildData = {};
              let newProData = {};
              room.children.forEach(child=>{
                if(child.cellId === this.copyNodeId){
                  newChildData = {...child};
                  newChildData.id = null;
                  newChildData.cellId = newNodeData.id;
                  room.children.push(newChildData)
                  // console.log("copynewChildData", newChildData)
                }
              })
              room.products.forEach(product=>{
                if(product.cellId === this.copyNodeId){
                  newProData = {
                    cellId: newNodeData.id,
                    productId: product.productId,
                    productImage: product.productImage,
                    productModel: product.productModel,
                    productSpec: product.productSpec,
                    productPrice: product.productPrice,
                    productNumber: product.productNumber,
                  }
                  // console.log("copyProduct", product)
                  room.products.push(newProData)
                }
              })
              return room;
            })
            if(floor.name === this.nowSelectFloor.name){
              floor.jsonData = this.nowSelectFloor.jsonData
              this.nowSelectFloor.rooms = floor.rooms;
            }
            return floor;
          })
          this.roomListData = JSON.parse(
            JSON.stringify(this.nowSelectFloor.rooms)
          )
          this.roomListData = this.roomListData.filter(
            (room) =>
              room.isDelete == false || !room.hasOwnProperty('isDelete')
          )
          this.childNode.push(newNodeData)
          this.parentNode.setChildren(this.childNode)
          // console.log(this.childNode)
          // console.log(this.parentNode)
          this.graph.cleanSelection()
          // this.graph.select(cells)
        }
        return false
      })
      this.graph.bindKey('arrowup', () => {
        console.log("按下键盘的上箭头")
        const cells = this.graph.getSelectedCells();
        if (cells.length) {
          let changeNode = cells[0].store.data;
          console.log("changeNode",changeNode)
          changeNode.position.y = changeNode.position.y - 1;
          this.childNode = this.childNode.map(child=>{
            if(child.id===changeNode.id){
              child.position.y = changeNode.position.y;
            }
            return child;
          })
          this.graph.refresh(); // 刷新图形
          console.log("childNode",this.childNode)
          console.log("parentNode",this.parentNode)
        }
        return false
      })
      this.graph.bindKey('arrowdown', () => {
        console.log("按下键盘的下箭头")
        const cells = this.graph.getSelectedCells()
        if (cells.length) {
          //调整位置
          
        }
        return false
      })
      this.graph.bindKey('arrowleft', () => {
        console.log("按下键盘的左箭头")
        const cells = this.graph.getSelectedCells()
        if (cells.length) {
          //调整位置
          
        }
        return false
      })
      this.graph.bindKey('arrowright', () => {
        console.log("按下键盘的右箭头")
        const cells = this.graph.getSelectedCells()
        if (cells.length) {
          //调整位置
          
        }
        return false
      })

      this.graph.on('change:selection', () => {
        const command = this.graph.command
        if (command.canUndo()) {
          commandStack.push(command.undo)
        }

        if (command.canRedo()) {
          commandStack.push(command.redo)
        }
      })

      //节点缩放后
      this.graph.on('node:resized', ({ e, x, y, node, view }) => {
        this.getJsonData()
        this.nowSelectFloor.jsonData = JSON.stringify(this.jsonData)
        if (node.store.data.shape == 'image') {
          if (node.zIndex != 1) {
            this.floorList = this.floorList.map((floor) => {
              if (this.nowSelectFloor.name === floor.name) {
                floor.rooms = floor.rooms.map((room) => {
                  room.children = room.children.map((child) => {
                    if (child.cellId === node.id) {
                      child.position = node.position
                    }
                    return child
                  })
                  room.products = room.products.map((product) => {
                    if (product.cellId === node.id) {
                      product.position = node.position
                    }
                    return product
                  })
                  return room
                })
              }
              return floor
            })
          }
        } else {
          let floor = this.floorList.find(
            (item) => item.name === this.nowSelectFloor.name
          )
          floor.jsonData = this.nowSelectFloor.jsonData
        }
      })
      this.graph.on('cell:change:angle', ({ cell, angle }) => {
        this.getJsonData()
        this.nowSelectFloor.jsonData = JSON.stringify(this.jsonData)

        console.log('节点旋转', cell)
        if (cell.zIndex != 1) {
          if (
            cell.store.data.shape == 'rect' ||
            cell.store.data.shape == 'text-block'
          ) {
            let floor = this.floorList.find(
              (item) => item.name === this.nowSelectFloor.name
            )
            floor.jsonData = this.nowSelectFloor.jsonData
          }
          if (cell.store.data.shape == 'image') {
            this.floorList = this.floorList.map((floor) => {
              if (this.nowSelectFloor.name === floor.name) {
                floor.rooms = floor.rooms.map((room) => {
                  room.children = room.children.map((child) => {
                    if (child.cellId === cell.id) {
                      child.position = cell.store.data.position
                    }
                    return child
                  })
                  room.products = room.products.map((product) => {
                    if (product.cellId === cell.id) {
                      console.log(4444, product)
                      console.log(4444, cell)
                      product.position = cell.store.data.position
                    }
                    return product
                  })
                  return room
                })
              }
              return floor
            })
          }
        } else {
          this.floorList = this.floorList.map((floor) => {
            floor.rooms = floor.rooms.map((room) => {
              room.children = room.children.map((child) => {
                this.childNode.forEach((item) => {
                  if (child.cellId === item.id) {
                    child.position = item.position
                  }
                })
                return child
              })
              room.products = room.products.map((product) => {
                this.childNode.forEach((item) => {
                  if (product.cellId === item.id) {
                    product.position = item.position
                  }
                })
                return product
              })
              return room
            })
            return floor
          })
        }
      })

      this.graph.on('node:moved', ({ cell }) => {
        this.getJsonData()
        this.nowSelectFloor.jsonData = JSON.stringify(this.jsonData)
        if (cell.zIndex != 1) {
          if (
            cell.store.data.shape == 'rect' ||
            cell.store.data.shape == 'text-block'
          ) {
            let floor = this.floorList.find(
              (item) => item.name === this.nowSelectFloor.name
            )
            floor.jsonData = this.nowSelectFloor.jsonData
          }
          if (cell.store.data.shape == 'image') {
            this.floorList = this.floorList.map((floor) => {
              if (this.nowSelectFloor.name === floor.name) {
                floor.rooms = floor.rooms.map((room) => {
                  room.children = room.children.map((child) => {
                    if (child.cellId === cell.id) {
                      child.position = cell.store.data.position
                    }
                    return child
                  })
                  room.products = room.products.map((product) => {
                    if (product.cellId === cell.id) {
                      product.position = cell.store.data.position
                    }
                    return product
                  })
                  return room
                })
              }
              return floor
            })
          }
        } else {
          this.floorList = this.floorList.map((floor) => {
            floor.rooms = floor.rooms.map((room) => {
              room.children = room.children.map((child) => {
                this.childNode.forEach((item) => {
                  if (child.cellId === item.id) {
                    child.position = item.position
                  }
                })
                return child
              })
              room.products = room.products.map((product) => {
                this.childNode.forEach((item) => {
                  if (product.cellId === item.id) {
                    product.position = item.position
                  }
                })
                return product
              })
              return room
            })
            return floor
          })
        }
      })

      //监听历史信息
      this.graph.on('history:change', () => {
        this.canRedo = this.graph.canRedo()
        this.canUndo = this.graph.canUndo()
      })
      this.graph.on('history:undo', ({ cmds }) => {
        console.log('HistoryUndo', cmds)
        cmds = cmds.map((cmd) => {
          if (cmd.event === 'cell:change:position') {
            console.log('111HistoryUndo')
            this.getJsonData()
            this.nowSelectFloor.jsonData = JSON.stringify(this.jsonData)
          }
          if (cmd.event === 'cell:removed') {
            console.log('11111122', cmd)
            if (this.undoState && cmd.data.hasOwnProperty('props')) {
              //如果是删除设备后的撤销  添加设备
              if (cmd.data.props.shape == 'image') {
                let undoProduct = cmd.data.props.data
                undoProduct.cellId = cmd.data.id
                let floor = this.floorList.find(
                  (floor) => floor.name === this.nowSelectFloor.name
                )
                if (this.nowSelectRoom.label != '') {
                  let room = floor.rooms.find(
                    (room) => room.name === this.nowSelectRoom.name
                  )
                  // console.log(room);
                  let delproduct = room.products.find(
                    (p) => p.cellId === undoProduct.cellId
                  )
                  if (delproduct == undefined) {
                    let data = {
                      cellId: undoProduct.cellId,
                      productId: undoProduct.id,
                      productImage: undoProduct.thumbImage,
                      productModel: undoProduct.modelName,
                      productSpec: undoProduct.productSpec,
                      productPrice: undoProduct.productPrice,
                    }
                    room.products.push(data)
                    room.children.push(undoProduct)
                  } else {
                    room.products = room.products.map((product) => {
                      if (product.cellId === undoProduct.cellId) {
                        product.isDelete = false
                        room.children.push(undoProduct)
                      }
                      return product
                    })
                  }
                } else {
                  floor.rooms = floor.rooms.map((room) => {
                    let delproduct = room.products.find(
                      (p) => p.cellId === undoProduct.cellId
                    )
                    if (delproduct != undefined) {
                      room.products = room.products.map((product) => {
                        if (product.cellId === undoProduct.cellId) {
                          product.isDelete = false
                          room.children.push(undoProduct)
                        }
                        return product
                      })
                    }
                    return room
                  })
                }
              }
              this.childNode.push(cmd.data.props)
              this.parentNode.setChildren(this.childNode)
              console.log(this.childNode)
              console.log(this.parentNode)
              this.getJsonData()
              this.nowSelectFloor.jsonData = JSON.stringify(this.jsonData)
              this.roomListData = this.nowSelectFloor.rooms
              this.roomListData = this.roomListData.filter(
                (room) =>
                  room.isDelete == false || !room.hasOwnProperty('isDelete')
              )
              this.updateKey = !this.updateKey
              console.log('删除撤销后的floorList', this.floorList)
            }
          }
        })
      })
      this.graph.on('history:redo', ({ cmds }) => {
        cmds = cmds.map((cmd) => {
          if (cmd.event === 'cell:change:position') {
            this.getJsonData()
            this.nowSelectFloor.jsonData = JSON.stringify(this.jsonData)
          }
          if (cmd.event === 'cell:added') {
            if (this.undoState && cmd.data.hasOwnProperty('props')) {
              //添加设备后撤销再重做  添加设备
              if (cmd.data.props.shape == 'image') {
                let undoProduct = cmd.data.props.data
                undoProduct.cellId = cmd.data.id
                let floor = this.floorList.find(
                  (floor) => floor.name === this.nowSelectFloor.name
                )
                if (this.nowSelectRoom.label != '') {
                  let room = floor.rooms.find(
                    (room) => room.name === this.nowSelectRoom.name
                  )
                  let delproduct = room.products.find(
                    (p) => p.cellId === undoProduct.cellId
                  )
                  if (delproduct == undefined) {
                    let data = {
                      cellId: undoProduct.cellId,
                      productId: undoProduct.id,
                      productImage: undoProduct.thumbImage,
                      productModel: undoProduct.modelName,
                      productSpec: undoProduct.productSpec,
                      productPrice: undoProduct.productPrice,
                    }
                    room.products.push(data)
                    room.children.push(undoProduct)
                  } else {
                    room.products = room.products.map((product) => {
                      if (product.cellId === undoProduct.cellId) {
                        product.isDelete = false
                        room.children.push(undoProduct)
                      }
                      return product
                    })
                  }
                } else {
                  floor.rooms = floor.rooms.map((room) => {
                    let delproduct = room.products.find(
                      (p) => p.cellId === undoProduct.cellId
                    )
                    if (delproduct != undefined) {
                      room.products = room.products.map((product) => {
                        if (product.cellId === undoProduct.cellId) {
                          product.isDelete = false
                          room.children.push(undoProduct)
                        }
                        return product
                      })
                    }
                    return room
                  })
                }
              }
              this.childNode.push(cmd.data.props)
              this.parentNode.setChildren(this.childNode)
              this.getJsonData()
              this.nowSelectFloor.jsonData = JSON.stringify(this.jsonData)
              this.roomListData = this.nowSelectFloor.rooms
              this.roomListData = this.roomListData.filter(
                (room) =>
                  room.isDelete == false || !room.hasOwnProperty('isDelete')
              )
              this.updateKey = !this.updateKey
            }
          }
        })
      })

      this.graph.on('cell:removed', ({ cell }) => {
        this.floorList = this.floorList.map((floor) => {
          if (floor.name == this.nowSelectFloor.name) {
            floor.rooms = floor.rooms.map((room) => {
              room.children = room.children.filter((c) => c.cellId !== cell.id)
              let delProduct = room.products.find((p) => p.cellId === cell.id)
              if (delProduct && delProduct.hasOwnProperty('isDelete')) {
                delProduct.isDelete = true
              } else {
                room.products = room.products.filter(
                  (p) => p.cellId !== cell.id
                )
              }
              return room
            })
            this.getJsonData()
            this.nowSelectFloor.jsonData = JSON.stringify(this.jsonData)
            floor.jsonData = this.nowSelectFloor.jsonData
          }
          return floor
        })
        if (this.childNode) {
          this.childNode = this.childNode.filter(
            (child) => child.id !== cell.id
          )
        }
        // 删除 this.parentNode 中的对应 cell
        if (this.parentNode && Array.isArray(this.parentNode.children)) {
          this.parentNode.setChildren(
            this.parentNode.children.filter((child) => child.id !== cell.id)
          )
        }
        this.roomListData = this.nowSelectFloor.rooms
        this.roomListData = this.roomListData.filter(
          (room) => room.isDelete == false || !room.hasOwnProperty('isDelete')
        )
        this.updateKey = !this.updateKey
      })
    },
    onUndo() {
      this.undoState = true
      this.graph.undo()
    },
    onRedo() {
      this.redoState = true
      this.graph.redo()
    },
    addShape() {
      if (this.addShapeState) {
        const commonAttrs = {
          body: {
            fill: '#fff',
            stroke: '#8f8f8f',
            strokeWidth: 1,
          },
        }
        const inNode = this.graph.createNode({
          shape: 'rect',
          x: 300,
          y: 240,
          width: 100,
          height: 40,
          // label: 'rect',
          attrs: commonAttrs,
        })

        this.childNode.push(inNode)
        this.parentNode.addChild(inNode)

        this.getJsonData()
        this.nowSelectFloor.jsonData = JSON.stringify(this.jsonData)
        let floor = this.floorList.find(
          (item) => item.name === this.nowSelectFloor.name
        )
        floor.jsonData = this.nowSelectFloor.jsonData
      }
    },
    openAddTextDialog() {
      this.addTextDialogVisible = true
    },
    addText() {
      if (this.addTextState) {
        console.log(this.inputAddTexts)
        const node = this.graph.createNode({
          x: 300,
          y: 240,
          width: 100,
          height: 40,
          shape: 'text-block',
          text: this.inputAddTexts,
          attrs: {
            body: {
              fill: 'rgba(239, 219, 255, 0)',
              stroke: 'rgba(146, 84, 222, 0)',
              // rx: 4,
              // ry: 4,
            },
          },
          angle: 0,
        })

        this.childNode.push(node)
        this.parentNode.addChild(node)

        this.getJsonData()
        this.nowSelectFloor.jsonData = JSON.stringify(this.jsonData)
        let floor = this.floorList.find(
          (item) => item.name === this.nowSelectFloor.name
        )
        floor.jsonData = this.nowSelectFloor.jsonData

        this.addTextDialogVisible = false
        this.inputAddTexts = ''
      }
    },
    //拖拽商品到设计方案
    async startDrag(row, e) {
      if (!(this.nowSelectFloor && this.nowSelectFloor.name)) {
        this.$message.error('请先设置楼层图纸')
        return
      }
      if(!(this.nowSelectRoom && this.nowSelectRoom.isRoot)) {
        this.$message.error('请先选择想要设置的房间')
        return
      }
      if(!row.modelName || !row.productPrice){
        this.$message.error('请先选择商品的型号和规格')
        return
      }
      let { data } = await ImgUploadOSS(row.thumbImage);
      console.log("ImgUploadOSS", data);
      const node = this.graph.createNode({
        shape: 'image',
        width: 120,
        height: 120,
        imageUrl: data,
        tooltip: row.productName,
        data: row, // 可以存储任意自定义数据
      })
      this.dnd.start(node, e)
      // if (this.nowSelectFloor && this.nowSelectFloor.name != '') {
      //   if (this.nowSelectRoom && this.nowSelectRoom.isRoot == true) {
      //     console.log(this.nowSelectRoom)
      //     // console.log(row)
      //     if (row.modelName != undefined && row.productPrice != undefined) {
      //       var img = new Image()
      //       img.src = row.thumbImage
      //       await new Promise((resovle, reject) => {
      //         img.onload = () => {
      //           resovle()
      //         }
      //       })

      //       const node = this.graph.createNode({
      //         shape: 'image',
      //         width: 120,
      //         height: 120,
      //         imageUrl: row.thumbImage,
      //         tooltip: row.productName,
      //         data: row, // 可以存储任意自定义数据
      //       })
      //       this.dnd.start(node, e)
      //     } else {
      //       this.$message.error('请先选择商品的型号和规格')
      //     }
      //   } else {
      //     this.$message.error('请先选择想要设置的房间')
      //   }
      // } else {
      //   this.$message.error('请先设置楼层图纸')
      // }
    },

    //取消创建点位图设计方案返回列表页
    returnOrderPage() {
      this.$router.push({ path: '/Solution/DesignIndex' })
    },
    //是否存在该点位图设计方案
    async editOrder() {
      if (this.editOrderData.name != '') {
        this.OrderDialogVisiable = false
      } else {
        this.$message.error('该设计方案不存在')
        this.$router.push({ path: '/Solution/DesignIndex' })
      }
    },
    //获取商品列表
    async getProductsData() {
      let { data } = await getProducts(this.form)
      const promises = data.data.map(async (item) => {
        let { data } = await getFilterProduct(item.productName)
        data.forEach((product) => {
          if (item.id == product.id) {
            item.attribute = product.attribute
            item.modelOptions = product.modelOptions
            item.productSpec = product.attribute[0].value
            item.productPrice = product.attribute[0].price
            item.modelName = product.modelOptions[0].value
            item.number = 1
          }
        })
      })
      await Promise.all(promises)
      if (this.form.pageIndex == 1) {
        this.productList = data.data
        this.form.total = data.total
      } else {
        this.productList = [...this.productList, ...data.data]
      }
      this.productList = this.productList.filter(
        (product) => product.onSale == true
      )
    },
    //商品列表加载更多
    async getProductList() {
      this.loading = true
      this.form.pageIndex++
      this.getProductsData()
      this.loading = false
    },
    //获取商品类型列表
    async getProductTypeList() {
      let { data } = await getAllType()
      this.proTypeOption = data
    },
    //获取商品品牌列表
    async getProductBrandList() {
      let { data } = await getAllBrand()
      this.proBrandOption = data
    },
    //商品品牌筛选
    async brandSelectChange() {
      this.form.pageIndex = 1
      this.getProductsData()
    },
    //商品类型筛选
    async typeSelectChange() {
      this.form.pageIndex = 1
      this.getProductsData()
    },
    //条件查询商品列表
    async searchProduct() {
      this.form.pageIndex = 1
      this.getProductsData()
    },
    //商品型号选择
    async attributeChange(row, selected) {
      console.log('selected', selected)
      row.productSpec = selected.value
      row.productPrice = selected.price

      // row.allPrice = row.number * row.productPrice
    },
    handleRemove(file) {
      this.fileList = this.fileList.filter((f) => f.uid !== file.uid)
    },
    // 上传图片成功回调
    handleUploadSuccess(res) {
      if (res.code != 200) {
        this.$message.error('文件上传失败' + res.msg)
        return
      }
      this.floorImageUrl = res.data
      this.fileList.push(res)
    },
    // 上传前loading加载
    handleBeforeUpload(file) {
      let isImg = false
      if (this.fileType.length) {
        let fileExtension = ''
        if (file.name.lastIndexOf('.') > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1)
        }
        isImg = this.fileType.some((type) => {
          if (file.type.indexOf(type) > -1) return true
          if (fileExtension && fileExtension.indexOf(type) > -1) {
            return true
          }
        })
      } else {
        isImg = file.type.indexOf('image') > -1
      }

      if (!isImg) {
        this.$message.error(
          `文件格式不正确,请上传${this.fileType.join('/')}图片格式文件!`
        )
        return false
      }
    },
    // 文件个数超出
    handleExceed() {
      this.$message.error(`上传的图片数量不能超过1个!`)
    },
    // 图片预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.data
      this.uploadDialogVisible = true
    },
    handleUploadError() {
      this.$message.error('上传失败')
    },
    uploadProcess(event, file, filelist) {
      console.log('上传进度' + file.percentage)
    },
    //新建楼层
    addNewFloor() {
      this.beginState = true
      if (this.createFloorData.name != '' && this.floorImageUrl != '') {
        this.createFloorData.fileUrl = this.floorImageUrl

        if (
          this.floorList.find((obj) => obj.name == this.createFloorData.name) ==
          undefined
        ) {
          this.floorList.push({ ...this.createFloorData })
          this.nowSelectFloor = this.floorList[this.floorList.length - 1]
          ;(this.nowSelectRoom = {
            label: '',
            isRoot: false,
            products: [],
          }),
            (this.oldJsonData = {})
          this.init()
        } else {
          let floor = this.floorList.find(
            (obj) => obj.name == this.createFloorData.name
          )
          if (floor.isDelete == true) {
            this.$message.error('该楼层已存在且被删除')
          } else {
            this.$message.error('该楼层已存在')
          }
        }

        this.createFloorData = {
          name: '',
          fileUrl: '',
          jsonData: '',
          rooms: [],
        }
        this.fileList = []
        this.floorDialogVisiable = false
        clearInterval(this.interval)
        this.interval = setInterval(() => {
          this.timerHandler()
        }, 10000)
        this.addShapeState = true
        this.addTextState = true
        setTimeout(() => {
          this.handleCurrentChange(this.nowSelectFloor)
        }, 200)
        this.isToggle = !this.isToggle
      } else {
        this.$message.error('楼层信息不完全，请填写完后重试')
      }
    },
    //删除楼层
    delFloor() {
      this.$confirm('是否确认删除该楼层?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          if (this.nowSelectFloor.hasOwnProperty('isDelete')) {
            this.floorList = this.floorList.map((floor) => {
              if (
                floor.name === this.nowSelectFloor.name &&
                floor.isDelete == false
              ) {
                floor.rooms = floor.rooms.map((room) => {
                  // let roomIndexList = [];
                  if (room.hasOwnProperty('isDelete')) {
                    room.products = room.products.map((product) => {
                      // let productIndexList = [];
                      if (product.hasOwnProperty('isDelete')) {
                        product.isDelete = true
                        return product
                      } else {
                        room.products = room.products.filter(
                          (p) => p.cellId !== product.cellId
                        )
                      }
                      room.children = room.children.filter(
                        (c) => c.cellId !== product.cellId
                      )
                    })
                    //
                    room.products = room.products.filter((p) => p !== undefined)
                    room.isDelete = true
                    return room
                  } else {
                    floor.rooms = floor.rooms.filter(
                      (r) => r.name !== room.name
                    )
                  }
                })
                floor.rooms = floor.rooms.filter((r) => r !== undefined)
                floor.isDelete = true
              }
              return floor
            })
          } else {
            //删除的是新楼层
            let delFloorIndex = this.floorList.findIndex(
              (floor) =>
                floor.name == this.nowSelectFloor.name &&
                !floor.hasOwnProperty('isDelete')
            )
            this.floorList.splice(delFloorIndex, 1)
          }
          this.isToggle = !this.isToggle

          this.nowSelectFloor = {
            fileUrl: '',
            name: '',
            jsonData: {},
            rooms: [],
          }
          this.roomListData = []
          this.init()
          this.$message.success('删除楼层成功')
        })
        .catch((error) => {
          this.$message.error('删除楼层失败')
        })
    },
    //新建房间
    addNewRoom() {
      if (this.nowSelectFloor && this.nowSelectFloor.name != '') {
        let data = {
          label: this.createRoomName,
          isRoot: true,
          children: [],
          name: this.createRoomName,
          products: [],
        }
        this.floorList.forEach((item) => {
          if (item.name === this.nowSelectFloor.name) {
            if (
              item.rooms.find((obj) => obj.name === this.createRoomName) ==
              undefined
            ) {
              item.rooms.push(data)
              this.roomListData = item.rooms
              this.roomListData = this.roomListData.filter(
                (room) =>
                  room.isDelete == false || !room.hasOwnProperty('isDelete')
              )
            } else {
              let room = item.rooms.find(
                (obj) => obj.name === this.createRoomName
              )
              if (room.isDelete == true) {
                this.$message.error('该房间已存在且被删除')
              } else {
                this.$message.error('该房间已存在')
              }
            }
          }
        })
        this.roomDialogVisiable = false
        this.createRoomName = '默认房间'
      } else {
        this.$message.error('请先选择楼层')
      }
    },
    //删除房间及商品
    delRoom(node, data) {
      //判断是否是旧数据
      if (data.hasOwnProperty('isDelete')) {
        if (node.level == 1) {
          data.children = data.children.map((child) => {
            this.jsonData.cells = this.jsonData.cells.filter(
              (cell) => cell.id !== child.cellId
            )
            this.childNode = this.childNode.filter((c) => c.id !== child.cellId)
            if (this.parentNode && Array.isArray(this.parentNode.children)) {
              this.parentNode.setChildren(
                this.parentNode.children.filter((pc) => pc.id !== child.cellId)
              )
            }
          })

          this.floorList = this.floorList.map((floor) => {
            floor.rooms = floor.rooms.map((room) => {
              if (room.id == data.id) {
                room.children = []
                room.products = room.products.filter((p) =>
                  p.hasOwnProperty('isDelete')
                )
                room.products = room.products.map((product) => {
                  product.isDelete = true
                  return product
                })
                room.isDelete = true
              }
              return room
            })
            if (floor.id === data.dragOrderFloorId) {
              floor.jsonData = JSON.stringify(this.jsonData)
              this.nowSelectFloor = floor
            }
            return floor
          })
        }
        if (node.level == 2) {
          this.jsonData.cells = this.jsonData.cells.filter(
            (cell) => cell.id !== data.cellId
          )
          console.log(this.jsonData.cells)
          this.childNode = this.childNode.filter((c) => c.id !== data.cellId)
          console.log(this.childNode)
          if (this.parentNode && Array.isArray(this.parentNode.children)) {
            this.parentNode.setChildren(
              this.parentNode.children.filter((pc) => pc.id !== data.cellId)
            )
          }

          this.floorList = this.floorList.map((floor) => {
            // let delFloorId = 0;
            floor.rooms = floor.rooms.map((room) => {
              if (room.id === data.dragOrderRoomId) {
                room.children = room.children.filter(
                  (d) => d.cellId !== data.cellId
                )
                room.products = room.products.map((product) => {
                  if (product.cellId === data.cellId) {
                    product.isDelete = true
                  }
                  return product
                })
                // delFloorId = floor.id
                floor.jsonData = JSON.stringify(this.jsonData)
                this.nowSelectFloor = floor
              }
              return room
            })
            return floor
          })
        }
        this.handleCurrentChange(this.nowSelectFloor)
      } else {
        //删除的是新添加
        if (node.level == 1) {
          data.children = data.children.map((child) => {
            this.jsonData.cells = this.jsonData.cells.filter(
              (cell) => cell.id !== child.cellId
            )
            this.childNode = this.childNode.filter((c) => c.id !== child.cellId)
            if (this.parentNode && Array.isArray(this.parentNode.children)) {
              this.parentNode.setChildren(
                this.parentNode.children.filter((pc) => pc.id !== child.cellId)
              )
            }
          })

          this.floorList = this.floorList.map((floor) => {
            if (floor.name === this.nowSelectFloor.name) {
              floor.rooms = floor.rooms.map((room) => {
                if (room.name === data.name) {
                  room.children = []
                  room.products = []
                  floor.jsonData = JSON.stringify(this.jsonData)
                  this.nowSelectFloor = floor
                  return null
                }
                return room
              })
              floor.rooms = floor.rooms.filter((r) => r != undefined)
            }
            return floor
          })
          console.log(this.floorList)
          this.handleCurrentChange(this.nowSelectFloor)
        }
        if (node.level == 2) {
          this.jsonData.cells = this.jsonData.cells.filter(
            (cell) => cell.id !== data.cellId
          )

          this.childNode = this.childNode.filter((c) => c.id !== data.cellId)
          if (this.parentNode && Array.isArray(this.parentNode.children)) {
            this.parentNode.setChildren(
              this.parentNode.children.filter((pc) => pc.id !== data.cellId)
            )
          }
          this.floorList = this.floorList.map((floor) => {
            if (floor.name === this.nowSelectFloor.name) {
              floor.rooms = floor.rooms.map((room) => {
                room.children.forEach((child) => {
                  console.log(child)
                })

                let rChildIndex = room.children.findIndex(
                  (d) => d.cellId === data.cellId
                )
                if (rChildIndex != -1) {
                  room.children.splice(rChildIndex, 1)
                  room.products = room.products.filter(
                    (p) => p.cellId !== data.cellId
                  )
                  floor.jsonData = JSON.stringify(this.jsonData)
                  this.nowSelectFloor = floor
                }
                return room
              })
            }
            return floor
          })
          this.handleCurrentChange(this.nowSelectFloor)
        }
      }

      this.updateKey = !this.updateKey
    },
    //当前选择楼层房间列表信息
    renderContent(h, { node, data }) {
      return (
        <span>
          {node.label}
          {data.isRoot &&
            data.children.length >= 0 &&
            ` (${data.children.length})`}
        </span>
      )
    },
    //点击选择房间 切换房间
    handleNodeClick(data) {
      this.nowSelectRoom = data
      this.graph.cleanHistory()
    },

    //房间里商品切换后重新获取房间列表
    getRoomList() {
      this.roomListData = [...this.nowSelectFloor.rooms]
      this.roomListData = this.roomListData.filter(
        (room) => room.isDelete == false || !room.hasOwnProperty('isDelete')
      )
    },
    //点击选择楼层 切换楼层
    handleCurrentChange(val) {
      if (val) {
        this.nowSelectFloor = val
        this.oldJsonData = {}
        if (this.nowSelectFloor.jsonData) {
          this.oldJsonData = JSON.parse(this.nowSelectFloor.jsonData)
        }
        ;(this.nowSelectRoom = {
          label: '',
          isRoot: false,
          products: [],
        }),
          (this.roomListData = [])
        this.roomListData = [...val.rooms]
        this.roomListData.forEach((room) => {
          room.isRoot = true
          room.children.forEach((item) => {
            item.label =
              item.productName +
              `_${item.productModel}_${item.productSpec}(x${item.productNumber})`
            item.isRoot = false
          })
        })
        this.roomListData = this.roomListData.filter(
          (room) => room.isDelete == false || !room.hasOwnProperty('isDelete')
        )
        console.log(3, this.roomListData)
        this.init()
        clearInterval(this.interval)
        this.interval = setInterval(() => {
          this.timerHandler()
        }, 10000)
        this.addShapeState = true
        this.addTextState = true
        this.beginState = true
      } else {
        this.beginState = false
      }
    },
    //点击触发加载更多
    moreListData() {
      this.getProductList()
    },
    //获取保存页面房间和种类商品列表
    getTypeProductList() {
      this.AllProductList = []
      this.roomProductList = []
      this.roomProductList = JSON.parse(JSON.stringify(this.floorList))
      this.allproductPrice = 0
      this.roomProductList = this.roomProductList.map((floor) => {
        floor.rooms = floor.rooms.map((room) => {
          let arrayList = []
          room.children = room.children.map((child) => {
            let info1 = this.proBrandOption.filter(
              (item) => item.value == child.productBrandId
            )[0]
            let info2 = this.proTypeOption.filter(
              (item) => item.value == child.productTypeId
            )[0]
            child.productBrandName = info1.label
            child.productTypeName = info2.label
            if (!child.hasOwnProperty('productId')) {
              child.productId = child.id
            }
            let arrayData = {}
            arrayData = { ...child }
            // arrayData.number = 1
            arrayData.allPrice = arrayData.number * arrayData.productPrice
            if (!arrayData.hasOwnProperty('isDelete')) {
              this.allproductPrice += arrayData.allPrice
            } else {
              if (!arrayData.isDelete) {
                this.allproductPrice += arrayData.allPrice
              }
            }
            arrayList.push(arrayData)
            return child
          })
          room.children = arrayList
          room.products = room.products.filter(
            (product) => product.isDelete == false
          )
          return room
        })
        floor.rooms = floor.rooms.filter(
          (room) => room.isDelete == false || !room.hasOwnProperty('isDelete')
        )
        return floor
      })
      this.roomProductList.forEach((floor) => {
        floor.rooms.forEach((room) => {
          room.children.forEach((product) => {
            if (this.AllProductList.length > 0) {
              //如果类型存在
              if (
                this.AllProductList.find(
                  (obj) => obj.type === product.productTypeName
                ) != undefined
              ) {
                this.AllProductList.forEach((item) => {
                  if (item.type === product.productTypeName) {
                    let pdata = {}
                    pdata = { ...product }
                    // pdata.number = 1
                    pdata.allPrice = pdata.number * pdata.productPrice
                    item.value.push(pdata)
                  }
                })
              }
              //类型不存在
              else {
                let data = {
                  type: '',
                  value: [],
                }
                data.type = product.productTypeName
                let pdata = {}
                pdata = { ...product }
                // pdata.number = 1
                pdata.allPrice = pdata.number * pdata.productPrice
                data.value.push(pdata)
                this.AllProductList.push(data)
              }
            } else {
              let data = {
                type: '',
                value: [],
              }
              data.type = product.productTypeName
              let pdata = {}
              pdata = { ...product }
              pdata.number = 1
              pdata.allPrice = pdata.number * pdata.productPrice
              data.value.push(pdata)
              this.AllProductList.push(data)
            }
          })
        })
      })
    },
    //打开保存设计方案弹出框
    openSaveDesignDialog() {
      this.getTypeProductList()
      this.saveDesignDialogVisible = true
    },
    //打开修改当前商品规格和型号的弹出框
    async openSpecDialog(value, floorName, roomName) {
      this.nowSelectProduct = { ...value }
      let { data } = await getFilterProduct(value.productName)
      this.nowSelectProduct.attribute = data[0].attribute
      this.nowSelectProduct.modelOptions = data[0].modelOptions
      this.nowSelectProduct.floorName = floorName
      this.nowSelectProduct.roomName = roomName
      this.SpecDialogVisible = true
    },
    //修改当前商品信息
    editNowProduct() {
      let floor = this.floorList.find(
        (item) => item.name === this.nowSelectProduct.floorName
      )
      let room = floor.rooms.find(
        (item) => item.label === this.nowSelectProduct.roomName
      )
      console.log(room)
      let product = room.products.find(
        (item) => item.cellId === this.nowSelectProduct.cellId
      )
      let child = room.children.find(
        (item) => item.cellId === this.nowSelectProduct.cellId
      )

      if (product) {
        product.productModel = this.nowSelectProduct.productModel
        product.modelName = this.nowSelectProduct.productModel
        product.productSpec = this.nowSelectProduct.productSpec
      }
      if (child) {
        child.productModel = this.nowSelectProduct.productModel
        child.modelName = this.nowSelectProduct.productModel
        child.productSpec = this.nowSelectProduct.productSpec
      }
      this.getTypeProductList()
      this.SpecDialogVisible = false
    },
    //删除当前商品
    DeleteNowProduct(value, floorName, roomName) {
      let floor = this.floorList.find((item) => item.name === floorName)
      let room = floor.rooms.find((item) => item.label === roomName)
      let nowFloorJson = JSON.parse(floor.jsonData)
      nowFloorJson.cells = nowFloorJson.cells.filter(
        (item) => item.id !== value.cellId
      )
      floor.jsonData = JSON.stringify(nowFloorJson)
      if (value.hasOwnProperty('isDelete')) {
        let product = room.products.find((item) => item.cellId === value.cellId)
        product.isDelete = true
      } else {
        room.products = room.products.filter(
          (item) => item.cellId !== value.cellId
        )
      }
      room.children = room.children.filter(
        (item) => item.cellId !== value.cellId
      )
      if (this.childNode) {
        this.childNode = this.childNode.filter(
          (child) => child.id !== value.cellId
        )
      }
      if (this.parentNode && Array.isArray(this.parentNode.children)) {
        this.parentNode.setChildren(
          this.parentNode.children.filter((child) => child.id !== value.cellId)
        )
      }
      this.handleCurrentChange(this.nowSelectFloor)
      this.getTypeProductList()
    },
    changeProductNumber(val, value, floorName, roomName) {
      let floor = this.floorList.find((item) => item.name === floorName)
      let room = floor.rooms.find((item) => item.label === roomName)
      let nowFloorJson = JSON.parse(floor.jsonData)

      floor.jsonData = JSON.stringify(nowFloorJson)

      let product = room.products.find((item) => item.cellId === value.cellId)

      if (product) {
        product.number = val
        product.productNumber = val
      }

      let productChild = room.children.find(
        (item) => item.cellId == value.cellId
      )

      if (productChild) {
        productChild.number = val
        productChild.productNumber = val
        productChild.label =
          productChild.productName +
          `_${productChild.modelName}_${productChild.productSpec}(x${productChild.number})`
      }

      this.handleCurrentChange(this.nowSelectFloor)
      this.getTypeProductList()
    },
    //取消点位图保存
    closeSaveDialog() {
      if (this.nowSelectFloor.name != '') {
        this.handleCurrentChange(this.nowSelectFloor)
      }
      this.saveDesignDialogVisible = false
    },

    //保存该点位图设计方案的编辑
    async editNewdragOrder() {
      this.editOrderData.floors = this.floorList
      await dragOrderEdit(this.editOrderData)
      let id = this.$route.params.id
      this.$router.push({ path: '/Solution/SlnDesignSuccess/' + id })
    },

    exitDesign() {
      this.$confirm('是否退出编辑方案返回方案列表?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          this.$router.push({ path: '/Solution/DesignIndex' })
        })
        .catch((error) => {})
    },
    //图纸每十秒自动保存一次
    timerHandler() {},
  },

  watch: {
    saveDesignDialogVisible(newValue, oldValue) {
      if (newValue) {
        clearInterval(this.interval)
      } else {
        clearInterval(this.interval)
        this.interval = setInterval(() => {
          this.timerHandler()
        }, 10000)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/dragorder.scss';
.app-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.tools_center {
  width: calc(100% - 550px);
  height: calc(100% - 19.75vh);
  margin: 0 auto;
  padding-top: 8vh;
}
.tools_center /deep/ .style-ruler {
  width: calc(100% - 550px) !important;
  height: calc(100% - 11.75vh) !important;
}
.tools_head {
  position: absolute;
  top: 10px;
  left: 275px;
  width: calc(100% - 550px);
  height: 6vh;
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tools_floatfoot {
  position: absolute;
  width: calc(100% - 550px);
  top: 94%;
  right: 275px;
  align-items: center;
  justify-content: right;
  display: flex;
}
.tools_floatfoot1 {
  width: 50px;
  height: 4vh;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 1px 1px 8px rgba(20, 20, 20, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.tools_floatfoot2 {
  width: 100px;
  height: 4vh;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 1px 1px 8px rgba(20, 20, 20, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  /deep/ .el-select {
    width: 42px;
  }
  /deep/ .el-select .el-input .el-input__inner {
    padding: 0;
    padding-left: 8%;
  }
  /deep/ .el-select .el-input .el-input__suffix {
    display: none;
  }
}
.tools_floatfoot1:hover {
  cursor: pointer;
}
.tools_floatfoot2:hover {
  cursor: pointer;
}
.tools_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 250px;
  height: calc(100% - 3.75vh);
  background: #eff3fb;
  box-sizing: border-box;
  padding: 10px;
  .floor_item {
    width: 100%;
    height: 36%;
    overflow: hidden;
    background: white;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 1.5vh;
    user-select: none;
  }
  .item {
    width: 100%;
    height: 60%;
    // min-height: 48vh;
    // max-height: 64vh;
    overflow: hidden;
    background: white;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    user-select: none;
  }
}
.tools_box2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 250px;
  height: calc(100% - 2.5vh);
  background: #ffffff;
  margin: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
  .item {
    width: 100%;
    height: calc(100% - 10vh);
    background: white;
    margin-bottom: 10px;
    user-select: none;
    padding-top: 10px;
  }
}
.hide {
  // display: none;
  /deep/.el-upload--picture-card {
    display: none;
  }
}

.save_box {
  display: inline-block;
  width: 100%;
  height: 72vh;
  /deep/.el-tabs__nav-wrap::after {
    height: 0 !important;
  }
  //修改样式
  /deep/ .el-tabs__item {
    width: 100px !important;
    padding: 0;
    text-align: center;
  }
  /deep/ .el-tabs__active-bar {
    width: 50px !important;
    margin: 0 0 0 25px;
    background-color: #8c50ff;
  }
  /deep/ .el-tabs__item.is-active {
    color: #8c50ff; // 选中标签的文字颜色
  }
  /deep/ .el-tabs__item:hover {
    color: #8c50ff; // 鼠标悬停时的文字颜色
  }
}

.tools_box3 {
  display: inline-block;
  width: 250px;
  height: 72vh;
  background: #ffffff;
  margin: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
  .item {
    width: 100%;
    height: 67vh;
    background: white;
    margin-bottom: 10px;
    user-select: none;
    padding-top: 10px;
  }
}

// .tools_box .item {
//   width: 100%;
//   height: 200px;
//   background: white;
//   margin-bottom: 10px;
//   user-select: none;
// }

.save_footer {
  border-radius: 15px;
  background: #ffffff;
  padding: 16px;
  padding-right: 32px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

#container {
  width: 100%;
  height: 100%;
}

.el-divider {
  margin: 10px 0;
}

.floor_table ::v-deep .el-table__row > td {
  border: none;
}

.floor_table .el-table--border::after, .el-table--group::after, .el-table::before {
  display: none;
}

.action-treebutton {
  display: none; // 默认隐藏按钮
}

/deep/.custom-tree-node:hover .action-treebutton {
  display: inline-block; // 鼠标悬停时显示按钮
}

.custom-tree /deep/ .el-tree-node__content {
  //   white-space: normal; /* 允许换行 */
  //   overflow: visible;   /* 显示溢出内容 */
  height: auto;
}

/deep/.el-input__inner:focus {
  border-color: #8c50ff !important;
}

/deep/.el-select .el-input__inner:focus {
  border-color: #8c50ff !important;
}
</style>
